<!--
 * @Descripttion: 
 * @version: 
 * @Author: zhangfei
 * @Date: 2020-11-26 10:28:54
 * @LastEditors: zhangfei
 * @LastEditTime: 2020-12-08 13:27:50
-->
<template>
    <div>
        <div class="page-header">创新设计中心</div>
        <div class="bannerList">
            <div class="bannerItem" @click="bannerIndex=0" :class="bannerIndex==0?'bannerActive':''">banner</div>
            <div class="bannerItem" @click="bannerIndex=1" :class="bannerIndex==1?'bannerActive':''">优秀设计师作品</div>
        </div>
        <div class="zt-block" style="height: calc(100% - 100px)">
            <div class="list" style="height: calc(100% - 60px);overflow-y: auto;padding: 24px 25px 30px 22px;">
                <div class="flex flex-justify-end" v-if="bannerIndex==1"> 
                    <el-button size="small" class="addBannerBtn" @click="addCreate">新增风格</el-button>
                </div>
                <div class="flex flex-justify-end" v-if="bannerIndex==0"> 
                    <el-button size="small" class="addBannerBtn" @click="addBanner">新增banner</el-button>
                </div>
                <el-table :data="list" v-if="bannerIndex===1" style="width: 100%">
                    <el-table-column prop="style_name" label="风格">
                        <template slot-scope="scope">
                            <div>{{ scope.row.style_name }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="order" label="排序">
                        <template slot-scope="scope">
                            <div>{{ scope.row.order }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="commodity_count" label="作品数">
                        <template slot-scope="scope">
                            <div>{{ scope.row.commodity_count }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作">
                        <template slot-scope="scope">
                            <el-button @click="viewDetail(scope.row)" type="text">编辑</el-button>
                            <el-button style="color: #f66f6a" @click="deleteDetail(scope.row)" type="text">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-table :data="list1" v-if="bannerIndex === 0" style="width: 100%">
          <el-table-column prop="name" label="banner名称">
            <template slot-scope="scope">
              <div>{{ scope.row.name }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="order" label="排序">
            <template slot-scope="scope">
              <div>{{ scope.row.order }}</div>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button @click="viewDetail1(scope.row)" type="text"
                >编辑</el-button
              >
              <el-button
                style="color: #f66f6a"
                @click="deleteDetail1(scope.row)"
                type="text"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
            </div>
            <div style="padding: 12px" v-if="bannerIndex ===1">
                <el-pagination
                    background
                    layout="prev, pager, next"
                    @current-change="handleCurrentChange" 
                    :current-page.sync="queryOpt.currentPage"
                    :page-size="queryOpt.pageSize"
                    :total="total"
                ></el-pagination>
            </div>
        </div>
        <el-dialog
            width="500px"
            :title="popTitle"
            :visible.sync="popVisible" 
            :destroy-on-close="true">
            <add-create v-if="bannerIndex === 1" :popVisible="popVisible" @hide="popVisible=false" @refreshItems="getList" :styleId="styleId"  :popTitle="popTitle" ></add-create>
            <add-banner v-if="bannerIndex === 0" @hide="popVisible=false" :editObj="editObj"  :isAdd="isAdd" @refreshItems="getBanner" :isStyle="true" :popTitle="popTitle" ></add-banner>
        </el-dialog>
    </div>
</template>
<script>
import { getStyleList,addStyle,getStyleDetail,editStyle,deleteCommodity,bannerList1,deleteBanner1 ,deleteGoods} from "@/service/operation";
import addCreate from './addCreate.vue'
import addBanner from '../tradCenter/addBanner.vue';
export default {
  data() {
    return {
        list:[],
        list1:[],
        queryOpt: {
            currentPage: 1,
            pageSize: 10,
        },
        total: 0,
        popTitle: "",
        popVisible: false,
        styleId:'',
        bannerIndex:0,
        editObj:{},
        isAdd:true
    }
  },
  created(){
      this.getBanner()
      this.getList()
  },
  components:{
      addCreate,addBanner
  },
  methods:{
      getBanner(){
          bannerList1().then(rst=>{
              this.list1 = rst.data.list;
          }).catch(err=>{
              this.$message.error(err.message);
          })
      },
      handleCurrentChange(val){
        this.queryOpt.currentPage = val
        this.getList()
      },
      getList(){
          getStyleList(this.queryOpt).then(rst=>{
            this.total = rst.data.pageInfo.total;
            this.list = rst.data.list;
          }).catch(err=>{
            this.$message.error(err.message);
          })
      },
      addBanner(){
          if(this.list1.length<5){
            this.popTitle = '新增banner'
            this.popVisible = true
            this.editObj = {}
          }else{
              this.$message.error('只能新增5个banner');
          }
          
      },
      addCreate(){
          this.popTitle = '新增作品'
          this.popVisible = true
      },
      viewDetail(row){
          this.popTitle = '编辑作品'
          this.popVisible = true
          this.styleId = row.id
      },
      viewDetail1(row){
          this.popTitle = '编辑Banner'
          this.popVisible = true
          this.isAdd = false;
          this.editObj = {}
          this.editObj = Object.assign({},row)
      },
      deleteDetail1(row){
        this.$confirm(`确认要删除吗?`, "温馨提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
        }).then(() => {
            deleteBanner1({bannerId:row.id}).then(res=>{
                this.$message.success("删除成功!");
                this.getBanner()
            }).catch(err=>{
                this.$message.error(err.message);
            })
           
        });
      },
      deleteDetail(row){
        this.$confirm(`确认要删除吗?`, "温馨提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
        }).then(() => {
            deleteCommodity({styleId:row.id}).then(res=>{
                this.$message.success("删除成功!");
                this.getList()
            }).catch(err=>{
                this.$message.error(err.message);
            })
           
        });
      }
  }
}
</script>
<style scoped>
.bannerList {
  padding: 20px 0 0 20px;
  display: flex;
}
.bannerItem {
  width: 130px;
  height: 36px;
  background: #e6e6e6;
  border-radius: 2px;
  text-align: center;
  margin-right: 20px;
  line-height: 36px;
  cursor: pointer;
  font-size: 14px;
}
.bannerActive {
  background: #5074ee;
  color: #ffffff;
}
</style>