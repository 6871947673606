<!--
 * @Descripttion: 
 * @version: 
 * @Author: zhangfei
 * @Date: 2020-11-26 10:46:05
 * @LastEditors: zhangfei
 * @LastEditTime: 2020-12-08 12:50:03
-->
<template>
  <div>
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
    >
      <el-form-item label="风格名称" prop="styleName">
        <el-input v-model="ruleForm.styleName"></el-input>
      </el-form-item>
      <el-form-item label="排序" prop="order">
        <el-input v-model="ruleForm.order"></el-input>
      </el-form-item>
      <span class="goods"
        >作品<span class="goodsNum"
          >（{{ ruleForm.commodity.length }}/8）</span
        ></span
      >
      <div
        v-for="(item, index) in ruleForm.commodity"
        :key="index"
        class="goodsInfo flex flex-v flex-center"
        style="align-items: end"
      >
        <el-form-item
          label="商品ID"
          :rules="[
            { required: true, message: '商品ID不能为空', trigger: 'change' },
          ]"
          :prop="'commodity.' + index + '.commodityId'"
        >
          <div class="flex" style="position: relative">
            <el-input v-model="item.commodityId"></el-input>
            <img
              src="../../../assets/images/return.png"
              @click="getGoodsInfo(index)"
              style="
                width: 20px;
                height: 20px;
                margin: 10px 0 10px 8px;
                cursor: pointer;
              "
            />
            <span v-if="item.errMesg" class="errorTip">{{ item.errMesg }}</span>
          </div>
        </el-form-item>
        <el-form-item label="商品名称">
          <span>{{ item.commodityName }}</span>
          <!-- <el-input v-model="item.commodityName"></el-input> -->
        </el-form-item>
        <el-form-item label="公司名称">
          <span>{{ item.companyName }}</span>
          <!-- <el-input v-model="item.companyName"></el-input> -->
        </el-form-item>
        <el-form-item
          label="商品图片"
          :rules="[
            { required: true, message: '请选择商品图片', trigger: 'change' },
          ]"
          :prop="'commodity.' + index + '.imgUrl'"
        >
          <el-upload
            class="comp-cert-uploader"
            action="xx"
            :show-file-list="false"
            :accept="uploadAccept"
            :http-request="uploadLegal"
            :before-upload="beforeUpload"
          >
            <!-- <img v-if="item.imgUrl" style="width:100px;height:100px" :src="item.imgUrl" class="avatar" /> -->
            <div v-if="item.imgUrl" style="position: relative">
              <img
                :src="item.imgUrl"
                style="width: 100px; height: 100px"
                class="avatar"
              />
              <i class="el-icon-close deleteImg" @click.stop="deleteImg" />
            </div>
            <div
              v-else
              class="flex flex-center"
              @click="commodityIndex = index"
            >
              <div
                class="flex flex-center flex-v uploadView"
                style="width: 100px; height: 100px"
              >
                <img
                  src="../../../assets/images/add.png"
                  style="width: 20px; height: 20px"
                />
                <span>立即上传</span>
              </div>
              <div class="sizeSuggest">
                <span>图片尺寸建议800px*800px;</span>
              </div>
            </div>
          </el-upload>
        </el-form-item>
        <img
          src="../../../assets/images/Sort.png"
          style="width: 20px; height: 20px"
          class="sort"
          @click="sortRow(index)"
        />
        <img
          src="../../../assets/images/delete.png"
          style="width: 20px; height: 20px"
          class="delete"
          @click="deleteRow(index)"
        />
      </div>
    </el-form>
    <div></div>
    <el-button class="addGoodsBtn" @click="addTradCenter">添加作品</el-button>
    <div class="flex flex-center footer">
      <el-button @click="hide()">取消</el-button>
      <el-button type="primary" @click="add()">确定</el-button>
    </div>
  </div>
</template>
<script>
import {
  addStyle,
  getStyleDetail,
  editStyle,
  deleteGoods,
} from "@/service/operation";
import { uploadFile, delFile } from "@/service/common";
import { IMG_URL_PRE, SERVER_ITEM_API } from "@/config";
import axios from "axios";
export default {
  data() {
    return {
      ruleForm: {
        commodity: [],
      },
      commodityIndex: 0,
      rules: {
        styleName: [
          { required: true, message: "请输入风格名称", trigger: "blur" },
        ],
        order: [{ required: true, message: "请输入排序", trigger: "blur" }],
        // commodityId: [
        //   { required: true, message: "商品ID不能为空", trigger: "blur" },

        // ],
        imgUrl: [
          { required: true, message: "商品图片不能为空", trigger: "blur" },
        ],
        companyName: [
          { required: true, message: "公司名称不能为空", trigger: "blur" },
        ],
        commodityName: [
          { required: true, message: "商品名称不能为空", trigger: "blur" },
        ],
      },
      uploadAccept: "image/jpeg,image/gif,image/png",
      errMesg: "",
    };
  },
  props: ["popTitle", "styleId", "popVisible"],
  methods: {
    getGoodsInfo(index) {
      this.errMesg = "";
      let data = { id: this.ruleForm.commodity[index].commodityId };
      axios
        .post(`${SERVER_ITEM_API}/design-market/detail-product`, data)
        .then((res) => {
          console.log("res=>", res);
          if (res.data.errcode === 1) {
            this.errMesg = res.data.message;
            this.ruleForm.commodity[index].errMesg = res.data.message;
          } else if (res.data.errcode === 0) {
            this.ruleForm.commodity[index].commodityName = res.data.result.name;
            this.ruleForm.commodity[index].companyName =
              res.data.result.design_comp.name;
          }
          this.ruleForm = Object.assign({}, this.ruleForm);
        })
        .catch((err) => {
          this.$message.error(err.message);
        });
    },
    getDetail() {
      getStyleDetail({ styleId: this.styleId })
        .then((res) => {
          this.ruleForm.styleName = res.data.style_name;
          this.ruleForm.styleId = res.data.id;
          this.ruleForm.order = res.data.order;
          if (res.data.commodity && res.data.commodity.length >= 0) {
            this.ruleForm.commodity = [];
            res.data.commodity.forEach((item, index) => {
              this.ruleForm.commodity.push({
                id: item.id,
                commodityName: item.commodity_name,
                companyName: item.company_name,
                imgUrl: item.img_url,
                commodityId: item.commodity_id,
              });
            });
          }
          console.log(this.ruleForm);
        })
        .catch((err) => {
          this.$message.error(err.message);
        });
    },
    uploadLegal(item) {
      uploadFile(item.file, "admin/portal/logo", {
        onUploadProgress: (event) => {
          item.file.percent = (event.loaded / event.total) * 100;
          item.onProgress(item.file);
        },
      })
        .then((url) => {
          console.log(url, IMG_URL_PRE);
          this.ruleForm.commodity[this.commodityIndex].imgUrl =
            IMG_URL_PRE + url;
          this.ruleForm = Object.assign({}, this.ruleForm);
        })
        .catch((err) => {
          console.log(err);
          this.$message.error(err.message);
        });
    },
    beforeUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 10;
      if (!isLt2M) {
        this.$message.error("上传LOGO图片大小不能超过 10MB!");
      }
      const isSize = new Promise(function (resolve, reject) {
        let width = 800;
        let height = 800;
        let _URL = window.URL || window.webkitURL;
        let img = new Image();
        img.onload = function () {
          let valid = img.width >= width && img.height >= height;
          valid ? resolve() : reject();
        };
        img.src = _URL.createObjectURL(file);
      }).then(
        () => {
          return file;
        },
        () => {
          this.$message.error("上传图片像素要大于800*800!");
          return Promise.reject();
        }
      );

      return isLt2M && isSize;
    },
    addTradCenter() {
      if (this.ruleForm.commodity.length < 9) {
        this.ruleForm.commodity.push({});
      }
    },
    sortRow(index) {
      if (index != 0) {
        this.ruleForm.commodity[index] = this.ruleForm.commodity.splice(
          index - 1,
          1,
          this.ruleForm.commodity[index]
        )[0];
      } else {
        this.ruleForm.commodity.push(this.ruleForm.commodity.shift());
      }
    },
    deleteImg() {
      // delFile(this.ruleForm.commodity[this.commodityIndex].imgUrl)
      //   .then((res) => {
      this.ruleForm.commodity[this.commodityIndex].imgUrl = "";
      this.ruleForm = Object.assign({}, this.ruleForm);
      // })
      // .catch((err) => {
      //   this.$message.error(err.message);
      // });
    },
    deleteRow(index) {
      deleteGoods({ commodityId: this.ruleForm.commodity[index].id })
        .then((res) => {})
        .catch((err) => {
          this.$message.error(err.message);
        });
      this.ruleForm.commodity.splice(index, 1);
      this.ruleForm = Object.assign({}, this.ruleForm);
    },
    add() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          if (this.popTitle === "编辑作品") {
            this.editSubmit();
          } else {
            this.submit();
          }
        }
      });
    },
    submit() {
      if (this.ruleForm.commodity.length <= 0) {
        this.$message.error("请先添加一个商品");
        return;
      }
      addStyle(this.ruleForm)
        .then((res) => {
          this.$emit("refreshItems");
          this.$emit("hide");
          this.$message.success("提交成功");
        })
        .catch((err) => {
          this.$message.error(err.message);
        });
    },
    editSubmit() {
      if (this.ruleForm.commodity.length <= 0) {
        this.$message.error("请先添加一个商品");
        return;
      }
      console.log(this.ruleForm);
      editStyle(this.ruleForm)
        .then((res) => {
          this.$emit("refreshItems");
          this.$emit("hide");
          this.$message.success("提交成功");
        })
        .catch((err) => {
          this.$message.error(err.message);
        });
    },
    hide() {
      this.$emit("hide");
    },
  },
  watch: {
    popVisible: {
      handler(newVal) {
        if (newVal) {
          if (this.popTitle === "编辑作品") {
            this.ruleForm = {
              styleId: "",
              styleName: "",
              order: "",
              type: 1,
              commodity: [],
            };
            this.getDetail();
          } else {
            this.ruleForm = {
              styleName: "",
              order: "",
              type: 1,
              commodity: [],
            };
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
<style scoped>
@import "../style.css";
</style>
<style>
.demo-ruleForm .el-input__inner {
  width: 260px;
}
.demo-ruleForm .el-input {
  width: 260px;
}
.demo-ruleForm .el-form-item {
  margin-bottom: 22px;
}
</style>